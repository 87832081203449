<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel Start here -->
        <panel title="DAFTAR PENGGUNA" class="panel panel-success">
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> LIST PEMBERI PARTISIPASI</h6>
            </template> -->
            <div class="pl-4 py-2 border shadow-sm">
                <span class="px-2"
                    ><b-button variant="primary" @click="create" pill
                        ><i class="fa fa-plus pr-1"></i> Tambah
                        Pengguna</b-button
                    ></span
                >
                <span class="px-2"
                    ><b-button variant="primary" @click="reload" pill
                        ><i class="fa fa-redo-alt pr-2"></i> Refresh</b-button
                    ></span
                >
            </div>
            <div class="py-3">
                <b-card class="shadow-sm rounded-0">
                    <!-- filter section -->
                    <b-row>
                        <b-col md="6" class="mb-3">
                            <b-form-group
                                :label-cols="3"
                                label="Filter By"
                                class="mb-0 my-1"
                            >
                                <b-input-group size="md">
                                    <b-form-select
                                        v-model="filterBy"
                                        :options="fieldOptions"
                                        @change="changeFilterBy()"
                                    >
                                        <option slot="first" :value="'all'"
                                            >All Data</option
                                        >
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group
                                :label-cols="3"
                                label="Filter"
                                class="mb-0"
                                description="Type to Search or Click Clear to Stop Searching "
                            >
                                <b-input-group size="md">
                                    <b-form-input
                                        v-model="filter"
                                        placeholder="Type to Search"
                                        debounce="500"
                                    />
                                    <b-input-group-append>
                                        <b-btn
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            variant="info"
                                            >Clear</b-btn
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" class="mt-1">
                            <b-form-group
                                :label-cols="3"
                                label="Sort"
                                class="mb-0 my-1"
                            >
                                <b-input-group size="md">
                                    <b-form-select
                                        v-model="sortBy"
                                        :options="fieldOptions"
                                    >
                                        <option slot="first" :value="null"
                                            >-- none --</option
                                        >
                                    </b-form-select>
                                    <b-form-select
                                        :disabled="!sortBy"
                                        v-model="sortDesc"
                                        slot="append"
                                    >
                                        <option :value="false">Asc</option>
                                        <option :value="true">Desc</option>
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <!-- end filter section -->
                    <!-- end Tools & Search -->

                    <!-- Data Table & Pagination -->
                    <b-table
                        show-empty
                        small
                        id="masTable"
                        ref="masTable"
                        head-variant="light"
                        :hover="true"
                        :busy.sync="isBusy"
                        :items="myGetData"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        bordered
                        striped
                    >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <template v-slot:table-colgroup="scope">
                            <col
                                v-for="field in scope.fields"
                                :key="field.key"
                                :style="{
                                    width:
                                        field.key === 'actions' ? '105px' : '',
                                }"
                            />
                        </template>
                        <template #head()="data">
                            <span style="white-space: pre;">{{
                                data.label
                            }}</span>
                        </template>

                        <template #cell(unit_pd)="data">
                            <div v-if="data.item.pegawai">
                                {{ data.item.pegawai.unit_pd.nama }}
                            </div>
                        </template>

                        <template #cell(perangkat_pd)="data">
                            <div v-if="data.item.pegawai">
                                {{
                                    data.item.pegawai.unit_pd.perangkat_pd.nama
                                }}
                            </div>
                        </template>

                        <template #cell(has_roles)="data">
                            <div v-for="(item, index) in data.item.has_roles" :key="index">
                                {{ item.name }}
                            </div>
                        </template>

                        <template #cell(suspend_at)="data">
                            <div  v-if="data.item.suspend_at" style="width: 150px">
                                <div class="label label-danger">
                                    Suspended
                                </div>
                                <div>
                                    {{ data.item.suspend_at }}
                                </div>
                            </div>
                            <div class="label label-success" v-else>
                                Active
                            </div>
                        </template>


                        <!-- button actions -->
                        <template v-slot:cell(actions)="data">
                            <b-dropdown
                                split
                                class="d-flex align-item-center"
                                size="sm"
                                variant="info"
                            >
                                <template #button-content>
                                    <i class="fa fa-cogs pr-1"></i> Aksi
                                </template>
                                <b-dropdown-item @click="send(data.item.id)"
                                    ><i class="fa fa-eye"></i> Kirim
                                    Password</b-dropdown-item
                                >
                                <b-dropdown-item @click="edit(data.item.id)"
                                    ><i class="fa fa-edit"></i>
                                    Edit</b-dropdown-item
                                >
                                <b-dropdown-item
                                    @click="
                                        swalNotification('error', data.item.id)
                                    "
                                    ><i class="fa fa-trash-alt"></i>
                                    Hapus</b-dropdown-item
                                >
                            </b-dropdown>
                        </template>
                        <!-- end button actions -->
                    </b-table>
                    <div class="divider"></div>

                    <!-- pagination section -->
                    <b-row>
                        <div class="divider"></div>
                        <b-col md="6" class="my-0">
                            <b-form-group
                                :label-cols="2"
                                label="Per page"
                                class="mb-0"
                            >
                                <b-form inline>
                                    <b-form-select
                                        :options="pageOptions"
                                        style="width: 100px;"
                                        v-model="perPage"
                                    />
                                    <label class="ml-1 mr-1">/</label>
                                    <b-form-input
                                        :disabled="true"
                                        v-model="totalRows"
                                        style="width: 100px;"
                                        class="text-right"
                                    />
                                    <label class="ml-1">Rows</label>
                                </b-form>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" class="my-0">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="md"
                                class="my-0 "
                            ></b-pagination>
                        </b-col>
                    </b-row>
                    <!-- end pagination section -->

                    <!-- end Data Table & Pagination -->
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
        <!-- Modal Tambah transaksi -->
        <b-modal
            :title="editMode ? 'Edit Data Pengguna' : 'Tambah Data Pengguna'"
            v-model="showModalForm"
            id="modal"
            hide-footer
            no-close-on-backdrop
        >
            <form
                @submit.prevent="editMode ? update(idUser) : store()"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >NPWRD
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="cekNpwrd.dataNpwrd"
                                    :reduce="(nama) => nama"
                                    v-model="npwrdData"
                                    placeholder="-Pilih NPWRD-"
                                    @input="inputNpwrd(npwrdData)"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Pegawai
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="Kepegawaian.pegawai"
                                    :reduce="(nama) => nama"
                                    v-model="pegawai"
                                    @input="inputPegawai(pegawai)"
                                    placeholder="-Pilih Pegawai-"
                                ></v-select>
                                <em
                                    v-if="form.errors.has('pegawai_id')"
                                    class="form-text text-danger"
                                    >{{ errors.pegawai_id[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Nama <span class="text-danger">*</span></label
                    >
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('name'),
                                    }"
                                    v-model="form.name"
                                    placeholder="Masukkan Nama"
                                />
                                <em
                                    v-if="form.errors.has('name')"
                                    class="form-text text-danger"
                                    >{{ errors.name[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Email <span class="text-danger">*</span></label
                    >
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="email"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('email'),
                                    }"
                                    v-model="form.email"
                                    placeholder="Masukkan Email"
                                />
                                <em
                                    v-if="form.errors.has('email')"
                                    class="form-text text-danger"
                                    >{{ errors.email[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Password <span class="text-danger">*</span></label
                    >
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="password"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'password'
                                        ),
                                    }"
                                    v-model="form.password"
                                    placeholder="Masukkan Password"
                                />
                                <em
                                    v-if="form.errors.has('password')"
                                    class="form-text text-danger"
                                    >{{ errors.password[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Has Role</label
                    >
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <b-form-group class="my-2">
                                    <b-form-checkbox-group
                                        id="checkbox-group-1"
                                        v-model="form.hasroles"
                                        :options="Role.role"
                                        value-field="name"
                                        text-field="name"
                                        name="flavour-1"
                                        stacked
                                    ></b-form-checkbox-group>
                                </b-form-group>
                                <em
                                    v-if="form.errors.has('hasroles')"
                                    class="form-text text-danger"
                                    >{{ errors.hasroles[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Suspend
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <b-form-radio-group
                                    v-model="suspend_user"
                                    :options="[
                                        {
                                            text: 'Ya',
                                            value: true
                                        },
                                        {
                                            text: 'Tidak',
                                            value: false
                                        }
                                    ]"
                                    class="my-2"
                                    @input="setSuspendAt"
                                ></b-form-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3" v-if="suspend_user">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Suspend At
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" v-model="form.suspend_at" class="form-control" disabled/>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <span>
                    <b-button variant="primary" type="submit">
                        <span v-if="isLoading">
                            <b-spinner
                                variant="primary"
                                label="Spinning"
                                small
                            ></b-spinner>
                        </span>
                        <i class="fa fa-save" v-else></i>
                        Simpan
                    </b-button>
                </span>
                <span class="float-right">
                    <b-button variant="outline-secondary" @click="close"
                        ><i class="fa fa-times"></i> Tutup</b-button
                    >
                </span>
            </form>
        </b-modal>
        <!-- end Modal -->

        <!-- Modal Loader-->
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Password Anda Sedang Di Kirim</div>
            </div>
        </b-modal>
        <!--- Modal View -->
        <b-modal
            title="Detail Pengguna"
            v-model="showModalView"
            id="modal-view"
            hide-footer
            no-close-on-backdrop
        >
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import Logo from "@/assets/images/logoSumut.png";
import axios from "axios";
import HeadTitle from "@/components/header/HRetribusi.vue";
import { mapState } from "vuex";
import hapusToken from "@/helper/hapusLocalStore";
import moment from "moment";

export default {
    mixins: [hapusToken],
    components: {
        HeadTitle,
    },
    data() {
        return {
            moment,
            selected: "1",
            imageLogo: Logo,
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            roleArr: ["Maintenance", "Administrator", "Pimpinan", "Pegawai"],
            role: JSON.parse(localStorage.getItem("roles"))[0],
            /*  columns: [{
				label: 'KODE',
				field: 'kode',
				tdClass: 'text-nowrap',
				thClass: 'text-nowrap'
			}, {
				label: 'NAMA URUSAN',
				field: 'nama',
				thClass: 'text-nowrap',
				tdClass: 'text-nowrap'
			}, {
				label: 'Opsi',
				field: 'action',
				type: 'percentage',
				width: '10%',
				tdClass: 'text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap',
				thClass: 'text-center text-nowrap'
			}],
      rows: [
        { id:1, kode: '1', nama:'Urusan Wajib'},
        { id:2, kode: '1', nama:'Urusan Wajib'},
        { id:3, kode: '1', nama:'Urusan Wajib'},
        { id:4, kode: '1', nama:'Urusan Wajib'},
	  ], */
            fields: [
                {
                    key: "index",
                    label: "No.",
                },
                {
                    key: "name",
                    label: "NAMA",
                    sortable: true,
                },
                {
                    key: "email",
                    label: "EMAIL",
                    sortable: true,
                },
                {
                    key: "perangkat_pd",
                    label: "PERANGKAT",
                    sortable: true,
                },
                {
                    key: "unit_pd",
                    label: "UNIT",
                    sortable: true,
                },
                {
                    key: "has_roles",
                    label: "Role",
                    sortable: true,
                },
                {
                    key: "suspend_at",
                    label: "Status",
                    sortable: false,
                },
                {
                    key: "actions",
                    label: "opsi",
                    sortable: false,
                },
            ],
            status_id: null,
            editMode: false,
            showModalForm: false,
            showModalView: false,
            form: new Form({
                npwrd_id: "",
                pegawai_id: "",
                name: "",
                email: "",
                password: "",
                hasroles: [],
                suspend_at: null
            }),
            errors: [],
            prov_id: "",
            isLoading: false,
            detailPengguna: [],
            idUser: "",
            showLoader: false,
            pegawai: '',
            npwrdData: null,
            suspend_user: false
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
        ...mapState(["filterTest", "cekNpwrd", "Kepegawaian", "Role"]),
    },
    mounted() {
        this.status_id = JSON.parse(localStorage.getItem("statusRegistrasi"));
        this.$store.dispatch("cekNpwrd/allNpwrd");
        this.$store.dispatch("Kepegawaian/getAllPegawai");
        this.$store.dispatch("Role/getRole");
    },
    methods: {
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/manajemen/user", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    statusRegistrasiId: this.status_id,
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch((error) => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapusToken Mixin
                        this.clearAll();
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
            localStorage.removeItem("filterView");
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/manajemen/user/" + id)
                        .then((response) => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 5000,
                            }).catch((error) => {
                                if (error.response.status === 401) {
                                    // hapusToken Mixin
                                    this.clearAll();
                                }
                            });
                        });
                }
                this.reload();
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditNPWP", params: { id: index } });
        },
        filterStatus(number) {
            this.status_id = number;
            this.reload();
        },
        // show modal
        create() {
            this.form.reset();
            this.form.clear();
            this.editMode = false;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
        },
        // show modal
        edit(id) {
            this.form.clear();
            this.editMode = true;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
            this.idUser = id;
            this.detailUser(id);
        },

        // kirim password
        send(id) {
            this.$root.$emit("bv::show::modal", "modalLoader");
            this.showLoader == true;
            axios
                .put("/api/manajemen/user/ganti-password/send-to-" + id)
                .then((response) => {
                    this.$root.$emit("bv::hide::modal", "modalLoader");
                    this.showLoader == false;
                    this.$swal({
                        icon: "success",
                        title: "Password berhasil dikirim ke email anda.",
                        text: "Silahkan periksa email anda untuk melihat password yang telah dikirim.",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false,
                        timer: 6000
                    });
                }).catch(error => {
                    this.$root.$emit("bv::hide::modal", "modalLoader");
                    this.showLoader == false;
                    if (error.response.status === 500) {
                        this.$swal({
                            icon: "error",
                            title: "Terjadi Masalah",
                            text: "Pengiriman password gagal. Silahkan hubungi administrator",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "OKE",
                            allowOutsideClick: false,
                            timer: 6000
                        });
                    }
                });
        },

        close() {
            this.form.reset();
            this.form.clear()
            this.$root.$emit("bv::hide::modal", "modal");
            this.showModalForm == false;
            this.suspend_user = false
        },
        // data detail provinsi
        detailUser(id) {
            axios
                .get("/api/manajemen/user/" + id)
                .then((response) => {
                    this.detailPengguna = response.data.data;
                    this.form.fill(this.detailPengguna);
                    const roleArr = [];
                    this.detailPengguna.has_roles.forEach((element) => {
                        roleArr.push(element.name);
                    });
                    this.form.hasroles = roleArr;
                    this.pegawai = this.detailPengguna.pegawai
                    if (this.pegawai) {
                        this.inputPegawai(this.pegawai)
                    }
                    this.errors = [];
                    if (this.form.suspend_at) {
                        this.suspend_user = true
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // hapus token mixins
                        this.clearAll();
                    }
                });
        },
        // store data provinsi
        store() {
            this.isLoading = true;
            this.form
                .post("/api/manajemen/user")
                .then((response) => {
                    this.isLoading = false;
                    this.form.reset();
                    this.pegawai = ''
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil ditambah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false,
                    });
                    this.reload();
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapus token mixins
                        this.clearAll();
                    }
                });
        },
        // store data provinsi
        update(id) {
            this.isLoading = true;
            this.form
                .put("/api/manajemen/user/" + id)
                .then((response) => {
                    this.isLoading = false;
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil dirubah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false,
                    });
                    this.reload();
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // ...
                    } else if (error.response.status === 405) {
                        this.$swal({
                            icon: "error",
                            title: "Terjadi Masalah",
                            text: error.response.data.message,
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "OKE",
                            allowOutsideClick: false,
                            timer: 5000,
                        });
                    }
                });
        },
        inputPegawai (data) {
            if (data) {
                this.form.pegawai_id = data.id
                this.form.name = data.nama
                this.form.email = data.email
            } else {
                this.form.pegawai_id = ''
                this.form.name = ''
                this.form.email = ''
            }
        },
        inputNpwrd (data) {
            if (data) {
                this.form.npwrd_id = data.id
                this.form.name = data.nama
                this.form.email = data.email
            }
        },
        setSuspendAt: function (event) {
            if (event) {
                this.form.suspend_at = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
            } else {
                this.form.suspend_at = null
            }
        }
    },
};
</script>

<style scoped>

</style>
